//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'app-header',

  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: 'auth/currentUserNameOrEmailPrefix',
      currentUserAvatar: 'auth/currentUserAvatar',
      isMobile: 'layout/isMobile',
      isScreen_md: 'layout/isScreen_md',
    }),
  },
  mounted() {},
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout',
      navigation: 'layout/navigation',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    handleDropdownCommand(command) {
      if (command === 'doSignout') {
        this.doSignout();
      }

      if (command === 'doEditProfile') {
        this.doEditProfile();
      }
    },
    doEditProfile() {
      return this.$router.push('/auth/edit-profile');
    },
  },
};
