import { storeAsync } from '@/app-module';
import i18n from '@/vueI18n'
export default {
    
  async beforeRouteEnter(to, from, next) {
    // debugger
    let { lang } = to.params
    // check for language and redirect to the same path with default language
    if (!lang || (lang !== 'ar' && lang !== 'en')){
      lang = localStorage.getItem('language') || 'en'
      i18n.locale = lang
      return next({ path: `/${lang}${to.path}` });
    }
    if(lang !== 'ar' && lang !== 'en') lang = 'en'
    i18n.locale = lang
    localStorage.setItem("language",lang)


    // console.log('Token ID =', to.query.tokenId);
    const token = to.query.tokenId
    if (!token && to.path !== `/${lang}/403`) {
      // firebase.auth().signInWithCustomToken(token);
      return next(`${lang}/403`);
    }
    if (to.path !== `/${lang}/403`) {
      const store = storeAsync();
      await store.dispatch('auth/doSignInWithCustomToken', token);
    }

    next();
    return;
//     if (!to.meta || !to.meta.auth) {
//       next();
//       return;
//     }

//     const store = storeAsync();

//     await store.dispatch('auth/doWaitUntilInit');

//     if (!store.getters['auth/signedIn']) {
//       next({ path: '/auth/signin' });
//       return;
//     }

//     if (
//       to.path !== '/auth/email-unverified' &&
//       !store.getters['auth/currentUser'].emailVerified
//     ) {
//       next({ path: '/auth/email-unverified' });
//       return;
//     }

//     if (
//       to.path !== '/auth/empty-permissions' &&
//       store.getters['auth/currentUser'].emailVerified &&
//       !store.getters['auth/roles'].length
//     ) {
//       next({ path: '/auth/empty-permissions' });
//       return;
//     }

//     next();
  },
};
