//
//
//
//
//
//
//
//
//

import ImporterToolbar from '@/shared/importer/components/importer-toolbar.vue';
import ImporterStatus from '@/shared/importer/components/importer-status.vue';
import ImporterList from '@/shared/importer/components/importer-list.vue';
import ImporterForm from '@/shared/importer/components/importer-form.vue';

export default {
  name: 'app-importer',

  props: ['storePath'],

  components: {
    [ImporterToolbar.name]: ImporterToolbar,
    [ImporterStatus.name]: ImporterStatus,
    [ImporterList.name]: ImporterList,
    [ImporterForm.name]: ImporterForm,
  },

  computed: {
    hasRows() {
      return this.$store.getters[
        `${this.storePath}/hasRows`
      ];
    },
  },
};
