//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { I18nUtil } from '@/shared/i18n/i18n-util';
import { getLanguages, getLanguageCode } from '@/i18n';
import i18n from '@/vueI18n'

export default {
  name: 'app-i18n-toggle',

  data() {
    return {
      lang: getLanguageCode(),
    };
  },

  computed: {
    languages() {
      return getLanguages();
    },
    isRTL() {
      return this.lang == 'ar'
    },
  },

  methods: {
    doChangeLanguage(language) {
      i18n.locale = language
      localStorage.setItem('language',language)
      const {lang} = this.$route.params
      let route = ''
      if(lang && (lang === 'ar' || lang === 'en')){
        route = `/${language}${this.$route.fullPath.substring(3)}`
      }
      else{
        route = `/${language}${this.$route.fullPath}`
      }
      if(language === 'ar'){
        document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
        document.getElementsByTagName("html")[0].setAttribute("lang","ar")
        document.getElementById('app').setAttribute("dir","rtl")
        this.$q.lang.rtl = true
        this.lang = 'ar'
      }
      else {
        document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
        document.getElementsByTagName("html")[0].setAttribute("lang","en-us")
        document.getElementById('app').setAttribute("dir","ltr")
        this.$q.lang.rtl = false
        this.lang = 'en'
      }
      this.$router.push(route)
      // I18nUtil.doChangeLanguage(language);
    },
  },
};
