//
//
//
//
//
//
//
//

export default {
  name: 'app-number-range-input',

  props: ['value'],

  computed: {
    startValue() {
      return this.value && this.value.length
        ? this.value[0]
        : undefined;
    },

    endValue() {
      return this.value && this.value.length > 1
        ? this.value[1]
        : undefined;
    },
  },

  methods: {
    handleInputStart(value) {
      this.$emit('input', [
        Number.isNaN(value) ? Number(value) : value,
        this.endValue,
      ]);
    },

    handleInputEnd(value) {
      this.$emit('input', [
        this.startValue,
        Number.isNaN(value) ? Number(value) : value,
      ]);
    },
  },
};
