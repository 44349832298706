// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB0MA2kMOqUXU05FtbcwbriWcsOWCPic7k",
  authDomain: "el-ghoneimy-dc.firebaseapp.com",
  projectId: "el-ghoneimy-dc",
  storageBucket: "el-ghoneimy-dc.appspot.com",
  messagingSenderId: "311301551671",
  appId: "1:311301551671:web:db0d64484e84025fc471b2",
  measurementId: "G-5Q386WNPX2"
};

const adminUrl = 'https://dc.elghoniemygroup.com'

// Cloud Functions
const backendUrl = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/api`;

const backendUrlAuth = `https://europe-west3-${
  firebaseConfig.projectId
}.cloudfunctions.net/auth`;

// App Engine
// const backendUrl = `<insert app engine url here>`;

export default {
  firebaseConfig,
  backendUrl,
  backendUrlAuth,
  adminUrl
};
