//
//
//
//
//
//
//
//
//

export default {
  name: 'app-view-item-text',

  props: ['label', 'value', 'prefix'],

  computed: {
    isBlank() {
      return !this.value || this.value === 0;
    },
  },
};
