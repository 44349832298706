// import firebase from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions
import 'firebase/auth';
import 'firebase/app-check';

import config from '@/config';



export default function firebaseInit() {
  const app = firebase.initializeApp(config.firebaseConfig);
  const appCheck = firebase.appCheck();
  appCheck.activate('6LfqxBYbAAAAAK_47AkrkznjlCwV35fF_8Ow7bmT');  
  app.auth();
}
