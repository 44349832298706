//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '@/config'

export default {
  name: 'app-error-403-page',

  computed: {
    url() {
      return config.adminUrl
    }
  }
};
