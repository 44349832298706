// import { storeAsync } from '@/app-module';
import i18n from '@/vueI18n'

export default {

  async beforeRouteEnter(to, from, next) {
    // debugger
    let { lang } = to.params
    // check for language and redirect to the same path with default language
    if (!lang || (lang !== 'ar' && lang !== 'en')) {
      lang = localStorage.getItem('language') || 'en'
      i18n.locale = lang
      return next({
        path: `/${lang}${to.path}`
      });
    }
    if (lang !== 'ar' && lang !== 'en') lang = 'en'
    i18n.locale = lang
    localStorage.setItem("language", lang)

    next();
    return;
    //     if (!to.meta || !to.meta.unauth) {
    //       next();
    //       return;
    //     }

    //     await storeAsync().dispatch('auth/doWaitUntilInit');

    //     if (storeAsync().getters['auth/signedIn']) {
    //       next('/');
    //     } else {
    //       next();
    //     }
  },
};