//
//
//
//
//
//
//
//

import { truncate } from 'lodash';

export default {
  name: 'app-list-item-file',

  props: ['value'],

  computed: {
    isBlank() {
      return !this.value || !this.value.length;
    },
  },

  methods: {
    truncate(name) {
      return truncate(name);
    },
  },
};
