//
//
//
//
//
//
//
//
//

export default {
  name: 'app-inner-loading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'common.loading',
    },
    size: {
      default: '2rem',
    },
    color: {
      type: String,
      default: 'RahalBlue'
    },
    dark: {
      type: Boolean,
      default: false,
    }
  },
};
