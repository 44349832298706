//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import i18n from '@/vueI18n'
// import firebase from 'firebase/app';
// import isElementInViewport from 'isElementInViewport'

export default {
  name: 'app',

  // created() {
  //   this.doInit();
  //   window.addEventListener('resize', this.handleResize);
  //   this.handleResize();
  // },
  async created() {
    const promises = [
      new Promise(() => this.doInitFirebase()),
      new Promise(() => this.doInit()),
    ];
    Promise.all(promises).then(
    )

    // window.addEventListener('load', this.addToCache(['/']));
    // await this.doInit();
    // firebase.auth().languageCode = localStorage.getItem('language');
    window.addEventListener('resize', this.handleResize);
    // document.addEventListener('scroll', this.handleScroll);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  methods: {
    ...mapActions({
      doInitFirebase: 'auth/doInitFirebase',
      doInit: 'auth/doInit',
      resize: 'layout/resize',
      navigation: 'layout/navigation',
    }),
    handleResize() {
      this.resize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    },
  },
  watch:{
    lang(newVal){
      if(newVal == 'ar') {
        document.getElementsByTagName("html")[0].setAttribute("dir","rtl")
        this.$q.lang.rtl = true
      }
      if(newVal == 'en') {
        document.getElementsByTagName("html")[0].setAttribute("dir","ltr")
        this.$q.lang.rtl = false
      }
    }
  },
  computed: {
    ...mapGetters({
      loadingInit: 'auth/loadingInit',
      tab: 'layout/tab',
    }),
    lang(){
      return i18n.locale
    }
  },
};
