import gql from 'graphql-tag';
import graphqlClient from '@/shared/graphql/client-auth';

export class LayoutService {
static async deleteUserFromAuthTable(uid) {

    const response = await graphqlClient.mutate({
      mutation: gql`
        mutation DELETE_USER_FROM_AUTH_TABLE($uid: String) {
            deleteUserFromAuthTable(uid: $uid)
        }
      `,

      variables: {
        uid,
      },
    });
    return response.data.deleteUserFromAuthTable;

    
  }
}