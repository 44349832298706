import service from '@/modules/auth/auth-service';
// import Message from '@/shared/message/message';
import Errors from '@/shared/error/errors';
import vueI18n from '@/vueI18n'
import { routerAsync } from '@/app-module';
import ProgressBar from '@/shared/progress-bar/progress-bar';
import firebase from 'firebase/app';
import 'firebase/auth'

export default {
  namespaced: true,

  state: {
    authenticationUser: null,
    currentUser: null,
    loadingInit: true,
    loading: false,
  },

  getters: {
    language: () => vueI18n.locale,
    authenticationUser: (state) => state.authenticationUser,
    currentUser: (state) => state.currentUser,
    currentUserEmail: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.email
        : null,
    currentUserFullName: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.fullName
        : '',

    signedIn: (state, getters) =>
      !!getters.currentUser && !!getters.currentUser.id,

    roles: (state, getters) =>
      getters.currentUser
        ? getters.currentUser.roles || []
        : [],

    emptyPermissions: (state, getters) =>
      !getters.roles || !getters.roles.length,

    loading: (state) => !!state.loading,
    loadingInit: (state) => !!state.loadingInit,

    currentUserNameOrEmailPrefix: (state, getters) => {
      if (!getters.currentUser) {
        return null;
      }

      if (
        getters.currentUserFullName &&
        getters.currentUserFullName.length < 25
      ) {
        return getters.currentUserFullName;
      }

      if (getters.currentUser.firstName) {
        return getters.currentUser.firstName;
      }

      return getters.currentUser.email.split('@')[0];
    },

    currentUserAvatar: (state, getters) => {
      if (
        !getters.currentUser ||
        !getters.currentUser.avatars ||
        !getters.currentUser.avatars.length ||
        !getters.currentUser.avatars[0].publicUrl
      ) {
        return null;
      }

      return getters.currentUser.avatars[0].publicUrl;
    },
  },

  mutations: {
    CURRENT_USER_REFRESH_SUCCESS(state, payload) {
      state.currentUser = payload.currentUser || null;
    },

    AUTH_START(state) {
      state.loading = true;
    },
    AUTH_SUCCESS(state, payload) {
      state.authenticationUser = payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loading = false;
    },
    AUTH_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loading = false;
    },

    AUTH_INIT_SUCCESS(state, payload) {
      state.authenticationUser =
        payload.authenticationUser || null;
      state.currentUser = payload.currentUser || null;
      state.loadingInit = false;
    },

    AUTH_INIT_ERROR(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },

    AUTH_NOT_FOUND_USER(state) {
      state.authenticationUser = null;
      state.currentUser = null;
      state.loadingInit = false;
    },
  },

  actions: {
//#region [ Init ]
    async doInitFirebase() {
      service.init();
    },
    async doInit({ commit }) {
      // await service.init();
      let currentUser = localStorage.getItem('currentUser');
      if (!currentUser) {
        commit('AUTH_INIT_SUCCESS', {
          currentUser: null,
          authenticationUser: null,
        });
        ProgressBar.done();
        return;
      }
      
      // const unsubscribe = service.onAuthStateChanged(
      //   (authenticationUser) => {
      //     dispatch('doSigninFromAuthChange', authenticationUser);
      //     unsubscribe();
      //   },
      //   () => {
      //     // console.error(error);
      //     commit('AUTH_INIT_ERROR');
      //   },
      // );
    },
    async doWaitUntilInit({ getters }) {
      if (!getters.loadingInit) {
        return Promise.resolve();
      }

      return new Promise((resolve) => {
        const waitUntilInitInterval = setInterval(() => {
          if (!getters.loadingInit) {
            clearInterval(waitUntilInitInterval);
            resolve();
          }
        }, 500);
      });
    },
//#endregion

    async doSignInWithCustomToken({ commit, getters }, token) {
      try {
        const userCredential = await firebase.auth().signInWithCustomToken(token)
        const user = userCredential['user']
        const UID = user['uid']
        const currentUser = await service.findUserByUid(UID)
        // console.log({ UID, user , currentUser, userCredential });
        commit('AUTH_SUCCESS', {
          currentUser: currentUser,
          authenticationUser: user,
        });
      } catch (error) {
        // console.log(error);
        commit('AUTH_ERROR');
        routerAsync().push(`/${getters.language}/403`);
      }
    },
    
    async doSigninFromAuthChange({ commit }, authenticationUser) {
      try {
        let currentUser = localStorage.getItem('currentUser');
        if (authenticationUser) {
          if (!currentUser) {
            currentUser = await service.fetchMe();
            localStorage.setItem(
              'currentUser',
              service.encryption(currentUser, 'secret-c-u'),
            );
          } else {
            currentUser = service.decryption(
              currentUser,
              'secret-c-u',
            );
          }

          // in background
          // service.reauthenticateWithStorageToken();
          currentUser.emailVerified = authenticationUser.emailVerified;
        }

        commit('AUTH_INIT_SUCCESS', {
          currentUser,
          authenticationUser: authenticationUser,
        });
        ProgressBar.done();
      } catch (error) {
        service.signout();
        Errors.handle(error);
        commit('AUTH_INIT_ERROR', error);
        ProgressBar.done();
      }
    },
    async doRefreshCurrentUser({ commit, getters }) {
      try {
        const authenticationUser = getters.authenticationUser;
        const currentUser = await service.fetchMe();
        currentUser.emailVerified = authenticationUser.emailVerified;
        
        //#region [ refresh CurrentUser in localStorage ]
        localStorage.setItem(
          'currentUser',
          service.encryption(currentUser, 'secret-c-u'),
        );
        //#endregion

        // in background
        service.reauthenticateWithStorageToken();

        commit('CURRENT_USER_REFRESH_SUCCESS', {
          currentUser,
        });
      } catch (error) {
        service.signout();
        Errors.handle(error);

        commit('CURRENT_USER_REFRESH_ERROR', error);
      }
    },
    async doSignout({ commit, dispatch }) {
      try {
        commit('AUTH_START');
        await service.signout();
        localStorage.clear();
        // localStorage.removeItem('currentUser');
        // localStorage.removeItem('token');
        commit('AUTH_SUCCESS', {
          authenticationUser: null,
          currentUser: null,
        });
        dispatch('doStopSplash');
        routerAsync().push('/auth/signin');
      } catch (error) {
        Errors.handle(error);
        commit('AUTH_ERROR');
      }
    },
  },
};
