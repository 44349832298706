//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  name: 'app-layout',
    
  data() {
    return {

    }
  },
  computed:{
    ...mapGetters({
      isScreen_md: 'layout/isScreen_md',
    })
  },
  methods:{
    i18n(key, args) {
      return this.$t(key, args);
    },
  }
};
