import { LayoutService } from '@/modules/layout/layout-service';

export default {
  namespaced: true,

  state: {
    tab: 'home',
    menuCollapsed: false,
    isMobile: false,
    isScreen_md: false,
    loadingAnonymous: false
  },

  getters: {
    tab: (state) => state.tab,
    menuCollapsed: (state) => state.menuCollapsed,
    isMobile: (state) => !!state.isMobile,
    isScreen_md: (state) => !!state.isScreen_md,

    paginationLayout: (state) =>
      state.isMobile
        ? 'total, prev, pager, next'
        : 'total, sizes, prev, pager, next',
    labelPosition: (state) =>
      state.isMobile ? 'top' : undefined,
    labelWidthForm: (state) =>
      state.isMobile ? undefined : '180px',
    labelWidthFilter: (state) =>
      state.isMobile ? undefined : '120px',
    loadingAnonymous: (state) => state.loadingAnonymous
  },

  mutations: {
    COLLAPSE_MENU(state) {
      state.menuCollapsed = true;
    },
    TOGGLE_MENU(state) {
      state.menuCollapsed = !state.menuCollapsed;
    },
    RESIZE(state, payload) {
      state.isMobile = payload.width < 576;
      state.isScreen_md = payload.width < 1024;
    },
    TOGGLE_TAP(state, payload) {
      state.tab = payload
    },
    DELETE_ANONYMOUS_STARTED(state){
      state.loadingAnonymous = true
    },
    DELETE_ANONYMOUS_SUCCESS(state){
      state.loadingAnonymous = false
    }
  },

  actions: {
    resize({ commit }, payload) {
      commit('RESIZE', payload);
    },

    toggleMenu({ commit }) {
      commit('TOGGLE_MENU');
    },

    collapseMenu({ commit }) {
      commit('COLLAPSE_MENU');
    },

    navigation({ commit }, payload) {
      commit('TOGGLE_TAP', payload);
    },
    async deleteUserFromAthTable({commit},uid){
      commit('DELETE_ANONYMOUS_STARTED')
      await LayoutService.deleteUserFromAuthTable(uid)
      commit('DELETE_ANONYMOUS_SUCCESS')

    }
  },
};
