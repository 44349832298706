//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'app-view-item-file',

  props: ['label', 'value'],

  computed: {
    isBlank() {
      return !this.value || !this.value.length;
    },
  },
};
