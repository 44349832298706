import FirebaseRepository from '@/shared/firebase/firebase-repository';
import Errors from '@/shared/error/errors';
// import { routerAsync } from '@/app-module';
// import Message from '@/shared/message/message';
// import { i18n } from '@/vueI18n';

export default {
  namespaced: true,

  state: {
    record: null,
    findLoading: false,
    saveLoading: false,
  },
  getters: {
    record: (state) => state.record,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    collectionName: () => 'files',
  },
  mutations: {
    RESET(state) {
      state.record = null;
      state.findLoading = false;
      state.saveLoading = false;
    },
    FIND_STARTED(state) {
      state.record = null;
      state.findLoading = true;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.findLoading = false;
    },
  },

  actions: {
    async doFind({ commit, getters}, id) {
      try {
        commit('FIND_STARTED');
        const record = await FirebaseRepository.findDocument(getters.collectionName, id)
        commit('FIND_SUCCESS', record);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        // routerAsync().push('/');
      }
    },
  },
};
