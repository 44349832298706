import Layout from '@/modules/layout/components/layout';
// import Permissions from '@/security/permissions';
// import { i18n } from '@/vueI18n';

const ExcelEditor = () => import('@/modules/editor/components/excel-editor.vue');
// const WordEditor = () => import('@/modules/editor/components/word-editor.vue');

export default [
  {
    name: '',
    // path: '',
    path: '/:lang?',
    // exact: true,
    component: Layout,
    // meta: { auth: true },
    children: [
      {
        props: true,
        name: 'excelEditor',
        path: '/:lang?/excel/:id',
        component: ExcelEditor,
        // exact: true,
        // meta: {
        //   auth: true,
        //   permission: Permissions.values.walletView,
        //   title: i18n('metaTitle.wallet')
        // },
        // beforeEnter: (to, from, next) => {
        //   document.title = to.meta.title
        //   next()
        // }
      },
      // {
      //   props: true,
      //   name: 'wordEditor',
      //   path: '/:lang?/word/:id',
      //   component: WordEditor,
      //   // meta: {
      //   //   auth: true,
      //   //   permission: Permissions.values.walletView,
      //   //   title: i18n('metaTitle.viewWallet') 
      //   // },
      //   // beforeEnter: (to, from, next) => {
      //   //   document.title = to.meta.title
      //   //   next()
      //   // },
      // },
    ],
  },
];
