import '@/shared/plugins/element';
import Vue from 'vue';
import Vuex from 'vuex';
import Router from 'vue-router';
import {
  setupComponentsFiltersDirectivesAndMixins,
  storeAsync,
  routerAsync,
} from '@/app-module';
import app from '@/app.vue';
import i18n from './vueI18n'
// import { SettingsService } from '@/modules/settings/settings-service';
// import ProgressBar from '@/shared/progress-bar/progress-bar';
// import { i18n } from '@/i18n';
import PortalVue from 'portal-vue';
import './quasar'
import { SpreadsheetPlugin } from '@syncfusion/ej2-vue-spreadsheet';
import { DocumentEditorContainerPlugin } from '@syncfusion/ej2-vue-documenteditor';
import { ButtonPlugin } from '@syncfusion/ej2-vue-buttons';
import { DropDownButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
import { registerLicense } from '@syncfusion/ej2-base'
import './assets/scss/index.scss'

(async function() {
  // document.title = i18n('app.title');
  // ProgressBar.start();
  // SettingsService.fetchAndApply();
  Vue.directive('click-outside', {
    bind () {
        this.event = event => this.vm.$emit(this.expression, event)
        this.el.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.event)
    },   
    unbind() {
      this.el.removeEventListener('click', this.stopProp)
      document.body.removeEventListener('click', this.event)
    },
  
    stopProp(event) { event.stopPropagation() }
  })
  Vue.use(Router);
  Vue.use(PortalVue);
  Vue.config.productionTip = process.env.NODE_ENV === 'production';
  Vue.use(Vuex);
  setupComponentsFiltersDirectivesAndMixins();

  // ======================================= New Installer ======================================= //
  registerLicense('Mgo+DSMBaFt/QHFqVVhkW1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9jTH5ad0dgXnpYdHZTQg==;Mgo+DSMBPh8sVXJ0S0d+XE9AcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xTc0VqWH1dd3FTQmRbVQ==;ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjUH5cc3NQQGRaVUA=;NjMzNDIxQDMyMzAyZTMxMmUzMFRxeTdTQ1hZVkt5NjJGUFFBSHFmVVQzU2dPU0h0V0NhSG9Sa3hxbC96Y0U9;NjMzNDIyQDMyMzAyZTMxMmUzMFJtZEVYQk9rZnExTlhEeWhDK0lBVjIxVEk5d0pGU2hOemNVR3B4aFhOeVU9;NRAiBiAaIQQuGjN/V0Z+XU9EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdEVmWXZfcndSQ2ZZVExy;NjMzNDI0QDMyMzAyZTMxMmUzMFlxUUl5WDBlK2dtbHljbWM0Y0RpRHBlc2dmaXZCNWRzeEJqVVVaazB0bEk9;NjMzNDI1QDMyMzAyZTMxMmUzMGhSeWs5UDF2eXF0MHdoVVVIQUNtczBXaHI4Wk5BZFpjTSs5WDF4eWhNTVE9;Mgo+DSMBMAY9C3t2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBjUH5cc3NQQGZaUEA=;NjMzNDI3QDMyMzAyZTMxMmUzME96RHNxTk5Va21pSE0xTDA4akUzWlVGS0dQYVozTkJjeDB6dzhnNHczK0k9;NjMzNDI4QDMyMzAyZTMxMmUzMGdOVENCNzVVc2VIdFd3R2dlOFdoZmQ3UnB6TVp4cGUyZTdCeUZWc1lXTEU9=')
  Vue.use(SpreadsheetPlugin);
  Vue.use(DocumentEditorContainerPlugin);
  Vue.use(ButtonPlugin);
  Vue.use(DropDownButtonPlugin);
  // ============================================================================================= //

  // eslint-disable-next-line
  new Vue({
    store: storeAsync(),
    router: routerAsync(),
    i18n,
    render: (h) => h(app)
  }).$mount('#app');
})();
